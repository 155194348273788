.carousel-container {
    width: 100%;
    list-style-type: none;
    display: flex;
}

.carousal {
    width: 100%;
}

.carousal-item-image {
    width: 100%;
    height: 250px;
}

@media screen and (min-width: 768px) {
    .carousel-container {
        align-items: center;
        justify-content: center;
    }

    .carousal-item-image {
        height: 350px;
    }
}